import React, { useState } from 'react';
import { Section2Container, Scroller, SubtitleWrapper, Graphics, Section6Container, LegendWrapper, LegendH5, LegendP, SliderWrapper, Range, CaptionWrapper, CaptionP, Subtitle, TextWrapper, Section7Container, ColorSpan, HoverText, CardContainer, CardP, InfoContainer, InfoWrapper, EmptyWrapper, NoticeSpan } from './Section5Elements';
import { Scrollama, Step } from 'react-scrollama';

const Section5 = () => {

  const [val, setVal] = useState(8);
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [currentProgress, setCurrentProgress] = useState({});

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };

  const onStepProgress = ({ progress, data }) => {
    setCurrentProgress(prog => ({ ...prog, [`element-${data}`]: progress }))
  };


  return (
    <>
      <Section2Container id="section3" url={`${currentStepIndex === 1 ? (process.env.REACT_APP_PUBLIC_URL + '/_map_' + val + '.png') : currentStepIndex === 2 ? (process.env.REACT_APP_PUBLIC_URL + '/_map_7.png') : ''}`}>

        <Scroller display={`${currentStepIndex === 0 ? 0 : 'max-content'}`}>
          <Scrollama offset={0.5} onStepEnter={onStepEnter} onStepProgress={onStepProgress}>
            {[1, 2, 3].map((_, stepIndex) => (
              <Step data={stepIndex} key={stepIndex}>
                <div>
                  {stepIndex === 0 &&
                    <EmptyWrapper />
                  }
                  {stepIndex === 1 &&
                    <SubtitleWrapper>
                      <h2 className="main-heading" style={{
                        visibility: currentStepIndex == 1 ? 'visible' : 'hidden',
                        position: 'fixed',
                        left: '5vw',
                        bottom: `${currentProgress[`element-${stepIndex}`] > 0.5 ? 50 : (currentProgress[`element-${stepIndex}`] * 100)}vh`
                      }}>
                        We mapped the most <span className="color-text">vulnerable</span> areas
                        <br></br>to boiling water alerts.
                      </h2>
                    </SubtitleWrapper>
                  }
                  {stepIndex === 2 &&
                    <SubtitleWrapper>
                      <div style={{
                        visibility: currentStepIndex == 2 ? 'visible' : 'hidden',
                        position: 'fixed',
                        left: '5vw',
                        bottom: `${currentProgress[`element-${stepIndex}`] > 0.5 ? 50 : (currentProgress[`element-${stepIndex}`] * 100)}vh`,
                        zIndex: 999
                      }}>
                        <h2 className="main-heading">
                          And analyzed the effects of water boiling alerts<br></br>
                          on unexcused absence rates in <span className="color-text hover-text">schools.
                            <div className="hover-text-container school-text">
                              <p>
                                Daily school attendance data (enrollment and unexcused absences) were collected for each of the district's 52 schools, including 31 elementary schools, 10 middle schools, and 7 high schools, from January 2015 to October 2021.
                              </p>
                            </div>
                          </span>
                        </h2>
                        <h2 className="main-heading heading-space">
                          In 2020, students lost between<br />
                          <span className="color-text">95,000 to 9.5 million</span> individual school days.
                        </h2>
                      </div>
                    </SubtitleWrapper>
                  }
                </div>
              </Step>
            ))}
          </Scrollama>
        </Scroller>
        <Graphics position={currentStepIndex === 0 ? true : false}>
          {currentStepIndex === 0 &&
            <InfoContainer id="section5" >
              <h2 className="main-heading">Who bears the burden of the water issues?</h2>
            </InfoContainer>
          }
          {currentStepIndex === 1 &&
            <Section6Container id="section6" url={process.env.REACT_APP_PUBLIC_URL + '/_map_' + val + '.png'}>
              <LegendWrapper>
                <LegendH5>Water boiling notices</LegendH5>
                <LegendP>
                  <ColorSpan color="#4BBFA9" />
                  <NoticeSpan>0</NoticeSpan>
                </LegendP>
                <LegendP>
                  <ColorSpan color="#FDF8C1" />
                  <NoticeSpan>1</NoticeSpan>
                </LegendP>
                <LegendP>
                  <ColorSpan color="#F3754E" />
                  <NoticeSpan>2</NoticeSpan>
                </LegendP>
                <LegendP>
                  <ColorSpan color="#ED1849" />
                  <NoticeSpan>3 or more</NoticeSpan>
                </LegendP>
                <LegendP>
                  <ColorSpan />
                  <NoticeSpan>Schools</NoticeSpan>
                </LegendP>
              </LegendWrapper>
              <SliderWrapper>
                <Range type="range" min="0" max="8" value={val} onChange={(e) => setVal(e.target.value)} />
                <CaptionWrapper>
                  <CaptionP>2010</CaptionP>
                  <CaptionP>2014</CaptionP>
                  <CaptionP>2015</CaptionP>
                  <CaptionP>2016</CaptionP>
                  <CaptionP>2017</CaptionP>
                  <CaptionP>2018</CaptionP>
                  <CaptionP>2019</CaptionP>
                  <CaptionP>2020</CaptionP>
                  <CaptionP>2021</CaptionP>
                </CaptionWrapper>
              </SliderWrapper>
            </Section6Container>
          }
          {currentStepIndex === 2 &&
            <Section7Container id="section7" url={process.env.REACT_APP_PUBLIC_URL + '/total_schools_outline.png'}></Section7Container>
          }
        </Graphics>
      </Section2Container>
    </>
  );
};

export default Section5;
