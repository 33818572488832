import React from 'react';
import Video from '../../videos/Intro_Jackson.mp4';
import { CoverContainer, CoverBg, CoverContent, VideoBg, CoverH2, CoverP, ScrollIcon, CoverSpan } from './CoverElements';
import { CircleDownIcon } from '../Icons/CircleDownIcon';

class CoverSection extends React.Component {
  render() {
    return (
      <CoverContainer>
        <CoverBg>
          <VideoBg
            ref={ref => (this.video = ref)} playsInline autoPlay muted loop preload='auto' src={Video} type='video/mp4' />
        </CoverBg>
        <CoverContent>
          <CoverH2>The Countdown<CoverSpan>,</CoverSpan></CoverH2>
          <CoverP>Jackson is boiling</CoverP>
        </CoverContent>
        <ScrollIcon to="infoSection" spy={true} smooth={true} duration={500}>
          <CircleDownIcon src={process.env.REACT_APP_PUBLIC_URL + '/arrow-down.png'} />
        </ScrollIcon>
      </CoverContainer>
    );
  }
};

export default CoverSection;
