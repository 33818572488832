import React from 'react';
import { CreditsSectionContainer, CreditsSectionWrapper, Subtitle, SubTitlesWrapper, DescWrapper, DescH2, Paragraph, DescP, TitleP, HyperLink, PWraper, HyperLink1, TextWrapper } from './CreditsElements';

import './footer.css'
const CreditsSection = () => {

  return (
    <>
      <CreditsSectionContainer id="CreditsSection">
        <CreditsSectionWrapper>
          <SubTitlesWrapper>
            <TitleP primary={false}>The Countdown,</TitleP>
            <Subtitle primary={true}>Jackson is boiling</Subtitle>
          </SubTitlesWrapper>
          <DescWrapper>
            <DescH2>Credits</DescH2>
            <Paragraph>
              <DescP primary={false}>Research Lead</DescP>
              <DescP primary={true}>Erica Walker, Brown University</DescP>
            </Paragraph>
            <Paragraph>
              <DescP primary={false}>Team</DescP>
              <DescP primary={true}>MIT Senseable City Lab</DescP>
              <PWraper>
                <DescP primary={true}>Carlo Ratti</DescP>
                <DescP primary={true}>Fábio Duarte</DescP>
                <DescP primary={true}>Martina Mazzarello</DescP>
                <DescP primary={true}>Arianna Salazar-Miranda</DescP>
              </PWraper>
              <DescP primary={true}>Brown University </DescP>
              <PWraper>
                <DescP primary={true}>Shaunessey Burke</DescP>
                <DescP primary={true}>Nina Franzen Lee</DescP>
                <DescP primary={true}>Del Rose Hooker Newball</DescP>
              </PWraper>
              <DescP primary={true}>Boston University School of Public Health</DescP>
              <PWraper>
                <DescP primary={true}>Koen Tieskens</DescP>
              </PWraper>
            </Paragraph>
            <Paragraph>
              <DescP primary={false}>Website</DescP>
              <DescP primary={true}>
                <HyperLink1 href="https://www.ats.adulis.com/" target="_blank">ATS</HyperLink1> (with A. S.-M., M. M., and F. D.)
              </DescP>
            </Paragraph>
          </DescWrapper>
          <DescWrapper>
            <DescH2>Publications</DescH2>
            <Paragraph>
              <DescP primary={true}>
                Walker, E., Kim, M., Devito, R., Duarte, F., Tieskens, R., Luna, M., Salazar-Miranda, A., Mazzarello, M., Otts, S., Etzel, C., Burks, S., Crossley, K., Lee, N. <HyperLink1 href="https://senseable.mit.edu/papers/pdf/202300410_Walker-etal_BoilWater_NatureWater.pdf" target="_blank">
                  Boil Water Alerts and their Impact on the Unexcused Absence Rate in Public Schools in Jackson, Mississippi. 
                </HyperLink1> Nature Water (2023)
              </DescP>
            </Paragraph>
            <Paragraph>
              <DescP primary={true}>Walker, Erica, Mississippi Boiling. Community Noise Lab, The Clarion Ledger, June 2022 </DescP>
            </Paragraph>
            <Paragraph>
              <DescP primary={true}>Boil Water Notices and its impacts on School Attendance </DescP>
            </Paragraph>
            <Paragraph>
              <DescP primary={false}>Funding</DescP>
              <DescP primary={true}>This Project has been funded by the Robert Wood Johnson Foundation </DescP>
            </Paragraph>
            <HyperLink href="https://www.dropbox.com/sh/4meget1ss5x11c1/AADlSGq1z01Jo-s9xj_JEy7aa?dl=0" target="_blank">Download Press Materials</HyperLink>
            <TextWrapper>
              <DescP primary={true}>
                Jackson’s residents can <HyperLink1 href="https://www.jacksonms.gov/jackson-water-info" target="_blank">take action</HyperLink1>
              </DescP>
            </TextWrapper>
          </DescWrapper>
        </CreditsSectionWrapper>
        <div className="footer-logo-wrapper">
          <ul>
            <li><a href="#"><img src="https://senseable.mit.edu/wip/jacksonboiling/webrebuild-master/build/mitlogo.png" /></a></li>
            <li><a href="#"><img src="https://senseable.mit.edu/wip/jacksonboiling/webrebuild-master/build/brownlogo.png" /></a></li>
          </ul>
        </div>
        <div className="accessibility-text">
          <a href="https://accessibility.mit.edu/">Accessibility</a>
        </div>
      </CreditsSectionContainer>
    </>
  );
};

export default CreditsSection;
