import React, { useState } from 'react';
import { Section2Container, SubtitleWrapper, Scroller, Graphics, DotDescP, DotDescWrapper, DotWrapper, DotWrapper1, DotWrapper2, Img, DotsWrapper, DotWrapper3, DotWrapper4, DotWrapper5, DotWrapper6 } from './Section2Elements';
import { InView } from 'react-intersection-observer';
import { Scrollama, Step } from 'react-scrollama';
import '../InfoSection/heading.css';
import './background-img.css'

const Section2 = ({ currentProgress, setCurrentProgress }) => {
  const [intervalId, setIntervalId] = useState()
  const [pt1, showPt1] = useState(false)
  const [pt2, showPt2] = useState(false)
  const [pt3, showPt3] = useState(false)
  const [currentPoint, setCurrentPoint] = useState(1)

  function handleChange(inView) {
    if (inView) {
      setTimeout(() => showPt1(true), 1500)
      setTimeout(() => showPt2(true), 3500)
      setTimeout(() => showPt3(true), 5500)

      let _intervalId = setInterval(() => {
        setCurrentPoint(_pt => (_pt % 7) + 1)
      }, 5000)

      setIntervalId(_intervalId)
    } else {
      showPt1(false)
      showPt2(false)
      showPt3(false)
      setCurrentPoint(1)
      clearInterval(intervalId)
    }
  }

  const onStepProgress = ({ progress }) => {
    setCurrentProgress(progress)
  };

  return (
    <InView as="div" onChange={handleChange}>
      <div className="background-img">
        <img src={require('../../images/_base_map.png')} />
      </div>
      <Scrollama offset={0.5} onStepProgress={onStepProgress}>
        <Step data={1}>
          <Section2Container id="section2">
            <Scroller />

            <div style={{
              position: 'absolute',
              bottom: `${(currentProgress > 0.5 ? 0.5 : currentProgress) * 100}vh`,
              zIndex: 999
            }}>
              <SubtitleWrapper>
                <h2 className="main-heading">
                  <span className="color-text hover-text">
                    Water boiling alerts
                    <div className="hover-text-container">
                      <h5>If you are under a boil-water notice</h5>
                      <p>Do not drink tap water.</p>
                      <p>Do not use ice made from recent tap water.</p>
                      <p>Do not use tap water to make drinks, juices, or fountain soft drinks.</p>
                      <p>Cook with tap water ONLY if food is boiled for at least one minute.</p>
                      <p>Wash dishes, fruits and vegetables in boiled water or water that has been disinfected</p>
                      <p>Using your home dishwasher is not recommended.</p>
                      <p>Brush your teeth with boiled or bottled water.</p>
                      <p>Wash your hands and bathe as usual. Bathing is safe as long as no water is swallowed. </p>
                    </div>
                  </span> indicate<br></br>
                  problems related to water quality.
                </h2>
              </SubtitleWrapper>

              <SubtitleWrapper style={{ marginBottom: 0 }}>
                <h2 className="main-heading">Water boiling alerts cost Jackson’s residents
                  <br></br>
                  between <span className="color-text">$5 to $10</span> per person per day.
                </h2>
              </SubtitleWrapper>
            </div>
            <Graphics>
              <DotsWrapper>
                <DotWrapper>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt1} />
                  <DotDescWrapper show={currentPoint == 1 && pt1}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper>

                <DotWrapper1>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt2} />
                  <DotDescWrapper show={currentPoint == 2}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper1>
                <DotWrapper2>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt2} />
                  <DotDescWrapper show={currentPoint == 3}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper2>
                <DotWrapper3>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt2} />
                  <DotDescWrapper show={currentPoint == 4}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper3>

                <DotWrapper4>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt3} />
                  <DotDescWrapper show={currentPoint == 5}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper4>
                <DotWrapper5>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt3} />
                  <DotDescWrapper show={currentPoint == 6}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper5>
                <DotWrapper6>
                  <Img src={require('../../images/dot-circle.png')} isShow={pt3} />
                  <DotDescWrapper show={currentPoint == 7}>
                    <DotDescP>Water is not safe</DotDescP>
                  </DotDescWrapper>
                </DotWrapper6>
              </DotsWrapper>
            </Graphics>
          </Section2Container>
        </Step>
      </Scrollama>
    </InView>
  );
};

export default Section2;
