import styled, { keyframes } from 'styled-components';
import { Link as LinkS } from 'react-scroll';

const lighter = keyframes`
  0% { opacity: 0.8 }
  100% { opacity: 0.5 }
`;

export const CoverContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  -webkit-box-shadow:0px 10px 10px rgba(0, 0, 0, 0.7);
  -moz-box-shadow:0px 10px 10px rgba(0, 0, 0, 0.7);
  box-shadow:0px 10px 10px rgba(0, 0, 0, 0.7);
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;    
    z-index: 2;
    background: black;
    animation: ${lighter} 3s normal forwards;
    animation-delay: 6s;
    opacity: 0.8;
  }
  z-index: 2;
`;

export const CoverBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

export const CoverContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CoverH2 = styled.p`
  color: #8CAA9D;
  font-size: 36px;
  text-align: center;
  font-family: Turret-Bold;  

  @media (max-width: 991.98px) {
    font-size: 32px;
  }
  @media (max-width: 575.98px) {
    font-size: 24px;
  }
`;

const blinking = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const CoverSpan = styled.span`
  color: #8CAA9D;
  font-size: 36px;
  font-family: Turret-Bold;
  animation: ${blinking} 2s normal forwards;
  animation-iteration-count: 4;

  @media (max-width: 991.98px) {
    font-size: 32px;
  }
  @media (max-width: 575.98px) {
    font-size: 24px;
  }
`;

const zoom = keyframes`
  0% { 
    opacity: 0;
    visibility: visible; 
  }
  100% { 
    opacity: 1;
    visibility: visible;
  }
`;

export const CoverP = styled.span`
  color: #fff;
  font-size: 29px;
  text-align: center;
  max-width: 600px;
  visibility: hidden;
  animation: ${zoom} 3s normal forwards;
  animation-delay: 7s;

  @media (max-width: 991.98px) {
    font-size: 25px;
  }
  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const ScrollIcon = styled(LinkS)`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    top: auto;
    margin: auto;
    cursor: pointer;
    color: #ffffff;
    z-index: 5;
    width: 46px;
    height: 47px;
`;
