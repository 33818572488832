import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Home from './pages';

import './fonts/Nunito-Regular.ttf';
import './fonts/TurretRoad-Bold.ttf';

function App() {
  return (
    <Router>
      <Home />
    </Router>
  );
}

export default App;
