import styled from 'styled-components';

export const Section2Container = styled.div`
  background-image: url(${props => props.url});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #252b2a;
  background-attachment: fixed;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 480px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media screen and (max-width: 1280px) {
    // padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Scroller = styled.div`
  width: max-content;
  position: relative;
  width: ${props => props.display};
`;

export const SubtitleWrapper = styled.div`
  margin: 100px auto;
  margin-left: 64px;
  padding: 325px 0;
  height: calc(100vh - 200px); 
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    margin-left: 24px;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 20px);
    padding: 0 120px;
    margin-left: 0;
  }
}
`;

export const Subtitle = styled.span`
  color: ${({ primary }) => (primary ? '#fff' : '#8CAA9D')}; 
  font-size: 29px;
  padding-right: 8px;
  @media screen and (max-width: 1024px) {
    font-size: 22px;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    text-align: center;
    padding-right: 4px;
  }  
`;

export const CardContainer = styled.div`
  background-color: #353E3B55;
  text-align: left;
  display: none;
  width: 490px;
  position: absolute;
  border-radius: 5px;
  padding: 16px;
  top: 50px;
  left: -228px;
  @media screen and (max-width: 480px) {
    top: 30px;
    left: -90%;
  }  
`;

export const CardContainer1 = styled.div`
  background-color: #353E3B55;
  text-align: left;
  display: none;
  width: 472px;
  position: absolute;
  border-radius: 5px;
  padding: 16px;
  top: 80px;
  left: -242px;
  @media screen and (max-width: 480px) {
    top: 50px;
    width: 90vw;
    left: -387%;
  }  
`;

export const CardH5 = styled.h5`
  color: #fff;
  font-size: 13px;
  margin: 8px 0px;
`;

export const CardP = styled.p`
  color: #fff;
  font-size: 13px;
  margin: 2px 0px;
`;

export const HoverText = styled.span`
  color: #8CAA9D;  
  font-size: 29px;
  cursor: pointer;
  position: relative;
  // text-decoration: ${({ primary }) => (primary ? 'none' : 'underline')};
  &:hover {
    ${CardContainer} {
      display: block;
    }
  }
  padding-right: 10px;  
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-right: 4px;
    z-index: 99;
  }  
`;

export const HoverText1 = styled.p`
  color: #8CAA9D;  
  font-size: 29px;
  cursor: pointer;
  position: relative;
  // text-decoration: ${({ primary }) => (primary ? 'none' : 'underline')};
  &:hover {
    ${CardContainer1} {
      display: block;
    }
  }
  padding-right: 10px;  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-right: 4px;
    z-index: 99;
  }  
`;

export const TextWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;


export const Graphics = styled.div`
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0px;
  animation: 0.5s linear 0s 1 normal none running fadeOut;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    position: ${props => props.position ? 'sticky' : 'fixed'};
    right: 0;
  }
`;

export const Section7Container = styled.div`
  background-color: transparent;
  transition: background-image 0.6s ease;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Section6Container = styled.div`
  background-color: transparent;
  transition: background-image 0.8s ease;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const SliderWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  right: 80px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1280px) {
    right: 30px;
  }
`;

export const Range = styled.input`
  height: 2px;
  min-width: 300px;
  z-index: 3;
  outline: none;  
  -webkit-appearance: none;
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: #8CAA9D;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 12px;
    width: 12px;
    background: #8CAA9D;
    border: 0;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: 12px;
    width: 12px;
    background: #8CAA9D;
    border: 0;
    transition: background-color 150ms;
    cursor: pointer;
  }
  
`;

export const CaptionP = styled.p`
  font-size: 13px;
  color: #fff;
  margin-top: 10px;
  align-self: end;  
`;

export const CaptionWrapper = styled.div`
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  margin: 0 -11px;
`;

export const LegendWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  right: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 1280px) {
    right: 30px;
  }
`;

export const LegendH5 = styled.h5`
  font-size: 15px;
  color: #fff;  
  margin-bottom: 10px;
`;

export const LegendP = styled.p`
  font-size: 13px;
  color: #fff;
  margin-right: 10px;
  align-items: center;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const NoticeSpan = styled.span`
  margin-right: 5px;
`;

export const ColorSpan = styled.span`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  background-color: ${props => props.color ? props.color : 'transparent'};
  border-color: ${props => props.color ? 'transparent' : 'white'};
  border: 1px solid;
`;

export const InfoContainer = styled.div`
  background: #222827;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  animation: 0.5s linear 0s 1 normal none running fadeOut;
  // opacity: ${props => props.opacity};
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  z-index: 1;  
  align-items: center;
  max-width: 680px;
  position: absolute;
  // padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyWrapper = styled.div`
  height: calc(100vh - 200px);
`;
