import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const CreditsSectionContainer = styled.div`
  background-image: url(total_schools_outline.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #262B2A;
  display: flex;
  flex-direction: column;
  padding: 80px 64px 70px;
  position: relative;
  overflow: hidden;
  z-index: 999999;
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;    
    z-index: 2;
    background: black;
    opacity: 0.9;
  }

  @media (max-width: 991.98px) {
    padding: 80px 30px 70px;
  }
  @media (max-width: 767.98px) {
    padding: 70px 30px;
  }
  @media (max-width: 575.98px) {
    padding: 70px 15px;
  }
`;

export const CreditsSectionWrapper = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: row;

  @media (max-width: 991.98px) {
    flex-wrap: wrap;
  }
`;

export const SubTitlesWrapper = styled.div`
  flex-basis: 33.333%;
  max-width: 100%;

  @media (max-width: 1199.98px) {
    flex-basis: 28%;
  }
  @media (max-width: 991.98px) {
    flex-basis: 50%;
  }
  @media (max-width: 767.98px) {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
`;

export const TitleP = styled.span`
  color: #8CAA9D;
  font-size: 32px;
  font-family: Turret-Bold;

  @media (max-width: 1199.98px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.p`
  color: ${({ primary }) => (primary ? '#fff' : '#8CAA9D')};  
  font-size: 25px;

  @media (max-width: 1199.98px) {
    font-size: 20px;
  } 
`;

export const DescWrapper = styled.div`
  flex-basis: 33.333%;
  max-width: 100%;
  position: relative;

  @media (max-width: 1199.98px) {
    flex-basis: 36%;
  }
  @media (max-width: 991.98px) {
    flex-basis: 50%;
    margin-bottom: 28px;
  }
  @media (max-width: 767.98px) {
    flex-basis: 100%;
    margin-bottom: 18px;
  }
  @media (max-width: 575.98px) {
    margin-bottom: 20px;
  }
`;

export const Paragraph = styled.div`
  margin: 20px 0;
  @media (max-width: 1199.98px) {
    margin: 17px 0;
  }
  @media (max-width: 767.98px) {
    margin: 12px 0;
  }
  @media (max-width: 575.98px) {
    margin: 10px 0;
  }
`;

export const DescH2 = styled.p`
  font-size: 24px;
  color: #8CAA9D;

  @media (max-width: 1199.98px) {
    font-size: 20px;
  }
  @media (max-width: 767.98px) {
    font-size: 18px;
  }
`;

export const DescP = styled.p`
  font-size: ${({ primary }) => (primary ? '18px' : '20px')};
  color: ${({ primary }) => (primary ? '#fff' : '#8CAA9D')};

  @media (max-width: 1199.98px) {
    font-size: ${({ primary }) => (primary ? '16px' : '18px')};
  }
  @media (max-width: 767.98px) {
    font-size: ${({ primary }) => (primary ? '15px' : '16px')};
  }
  @media (max-width: 575.98px) {
    font-size: ${({ primary }) => (primary ? '14px' : '16px')};
  }
`;

export const PWraper = styled.div`
  margin-bottom: 15px;
  margin-left: 20px;
  @media (max-width: 1199.98px) {
    margin-left: 17px;
  }
  @media (max-width: 767.98px) {
    margin-left: 15px;
  }
  @media (max-width: 575.98px) {
    margin-left: 12px;
    margin-bottom: 12px;
  }
`;


export const HyperLink = styled.a`
  color: #8CAA9D;
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;

  @media (max-width: 1199.98px) {
    font-size: 18px;
  }
  @media (max-width: 767.98px) {
    font-size: 16px;
  } 
`;

export const HyperLink1 = styled.a`
  color: #8CAA9D;
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;

  @media (max-width: 1199.98px) {
    font-size: 16px;
  }
  @media (max-width: 767.98px) {
    font-size: 15px;
  } 
  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  @media (max-width: 1199.98px) {
    margin: 17px 0;
  }
  @media (max-width: 767.98px) {
    margin: 12px 0;
  }
  @media (max-width: 575.98px) {
    margin: 10px 0;
  }
`;
