import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const InfoContainer = styled.div`
  background: #222827;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 64px;
  height: 100vh;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 0 30px;
  }
  @media (max-width: 575.98px) {
    padding: 0 15px;
  }
  z-index: 1;
`;

export const InfoWrapper = styled.div`
  z-index: 1;
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media (max-width: 991.98px) {
    flex-wrap: wrap;
  }
`;

export const SubTitlesWrapper = styled.div`
  // flex-basis: 42%;
  // max-width: 100%;

  @media (max-width: 1199.98px) {
    flex-basis: 45%;
  }
  @media (max-width: 991.98px) {
    flex-basis: 55%;
  }
  @media (max-width: 767.98px) {
    flex-basis: 100%;
  }
`;

export const Subtitle = styled.span`
  color: ${({ primary }) => (primary ? '#fff' : '#8CAA9D')};
  // text-decoration: ${({ primary }) => (primary ? 'none' : 'underline')};
  font-size: 29px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }  
`;

export const TextWrapper = styled.div`

`;

export const DotDescWrapper = styled.div`
  background-color: #a7a9a9;
  text-align: left;
  display: none;
  position: absolute;
  border-radius: 5px;
  padding: 6px;
  top: 0;
  margin-left: 50px;
  // opacity: 0.6;
  width: 390px;
  z-index: 4;
  @media screen and (max-width: 480px) {
    width: 90vw;
    top: 50px;
    right: 5vw;
    margin-left: 0px;
    min-width: auto;
  }
  @media screen and (max-width: 1780px) {    
    top: 50px;
    margin-left: -180px;   
  }
}
`;

export const DotDescP = styled.p`
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  margin: 2px 0px;
`;

export const DotCaptionP = styled.p`
  color: grey;
  font-size: 12px;
  margin-top: 8px;
`;

export const DotWrapper = styled.div`
  display: flex;
  position: relative;
  // opacity: 0.6;
  left: 40%;
  width: 60%;
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
`;

export const DotWrapper1 = styled.div`
  display: flex;
  position: absolute;
  margin-top: 50px;
  // opacity: 0.6;
  left: calc(40% + 40px);
  width: calc(60% - 40px);
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% + 30px);
    width: calc(60% - 30px);
    margin-top: 30px;
  }
`;

export const DotWrapper2 = styled.div`
  display: flex;
  position: absolute;
  margin-top: 50px;
  // opacity: 0.6;
  left: calc(40% - 120px);
  width: calc(60% + 120px);
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% - 100px);
    width: calc(60% + 100px);
    margin-top: 30px;
  }
`;

export const DotWrapper3 = styled.div`
  display: flex;
  position: absolute;
  margin-top: 140px;
  // opacity: 0.6;
  left: calc(40% + 150px);
  width: calc(60% - 150px);
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% + 110px);
    width: calc(60% - 110px);
    margin-top: 100px;
  }
`;

export const DotWrapper4 = styled.div`
  display: flex;
  position: absolute;
  bottom: 100px;
  // opacity: 0.6;
  left: calc(40% - 160px);
  width: calc(60% + 160px);
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% - 120px);
    width: calc(60% + 120px);
    bottom: 70px;
  }
`;

export const DotWrapper5 = styled.div`
  display: flex;
  position: absolute;
  // opacity: 0.6;
  left: calc(40% + 180px);
  width: calc(60% - 180px);
  bottom: 0px;
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% + 140px);
    width: calc(60% - 140px);
  }
`;

export const DotWrapper6 = styled.div`
  display: flex;
  position: absolute;
  // opacity: 0.6;
  left: calc(40% + 130px);
  width: calc(60% - 130px);
  bottom: 150px;
  &:hover {
    ${DotDescWrapper} {
      display: block;
    }
  }
  @media screen and (max-width: 780px) {
    left: calc(40% + 90px);
    width: calc(60% - 90px);
    bottom: 110px;
  }
`;

export const DotsWrapper = styled.div`
  width: 60%;
  position: relative;
  @media screen and (max-width: 480px) {
    margin-top: 80px;
    width: 100%;
  }
`;

export const Img = styled.img`
  width: 40px;
  height: auto;
`;

export const ImgLogo = styled.img`
  width: auto;
  height: 20px;
  margin: 2px 0 0 4px;
`;

export const ScrollIcon = styled(LinkS)`
    display: inline-block;
    position: absolute;
    color: #ffffff;
    bottom: 25px;
    left: 0;
    right: 0;
    top: auto;
    margin: auto;
    cursor: pointer;
    z-index: 2;
    width: 46px;
    height: 47px;
`;
