import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import logo from '../../images/logo.png';

export const Nav = styled.nav`
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 64px;
  margin-top: 30px;
  @media (max-width: 767.98px) {
    padding: 0 30px;
  }
  @media (max-width: 575.98px) {
    padding: 0 15px;
  }
`;

export const NavLogo = styled(LinkR)`
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;
export const LogoImage = styled.div`
    width: 170px;
    height: 36px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
`;
