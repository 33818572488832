import React, { useEffect, useRef, useState } from 'react';
import { CircleDownIcon } from '../Icons/CircleDownIcon';
import { InfoContainer, InfoWrapper, ScrollIcon, SubTitlesWrapper } from './InfoElements';
import { Scrollama, Step } from 'react-scrollama';
import './info-section.css'
import './heading.css'
import timelines from './timeline'

function compare(a, b) {
  a = a.split("_")[0]
  b = b.split("_")[0]

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

const InfoSection = ({ controlProgress }) => {
  const ref = useRef(null);
  const [active, setActive] = useState({ key: -1, item: null })
  const [hover, setHover] = useState(null)
  const [width, setWidth] = useState(window.innerWidth);
  const [currentProgress, setCurrentProgress] = useState(0);

  const onStepProgress = ({ progress }) => {
    setCurrentProgress(progress)
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleClickScroll = (counter) => {
    const newIndex = active.key + counter

    if (active.key > -1)
      ref.current.scrollLeft = ref.current.scrollLeft + (counter * 50);
    setActive({ key: newIndex, item: Object.keys(timelines).sort(compare)[newIndex] })
  };

  const isEnabled = width <= 575.98;
  const opacity = 1 - controlProgress * 8

  return (
    <Scrollama offset={0.5} onStepProgress={onStepProgress}>
      <Step data={1}>
        <InfoContainer id="infoSection" style={{ opacity }}>
          <InfoWrapper>
            <div className="heading-wrapper">
              <SubTitlesWrapper style={{
                position: 'absolute',
                bottom: `${(currentProgress > 0.5 ? 0.5 : currentProgress) * 100}vh`,
                zIndex: 999
              }}>
                <h2 className="main-heading">Water quality has been an issue<br></br>
                  in <span className="color-text">Jackson, Mississippi</span>, for 100 years.
                </h2>
              </SubTitlesWrapper>
            </div>
            <div className="timeline-image-wrapper">
              <div className="image-wrapper">
                {(active.item || hover) && <img src={`${process.env.REACT_APP_PUBLIC_URL}/timeline/${hover || active.item}.png`} />}
              </div>
              <div className="timeline-wrapper">
                {isEnabled && active.key > 0 &&
                  <span className="chevron left">
                    <button onClick={() => handleClickScroll(-1)}><img src="/arrow-down.png" /></button>
                  </span>
                }
                <div className="timeline" ref={ref}>
                  {
                    Object.keys(timelines).sort(compare).map((_timeline, _index) =>
                      <div
                        id={`section-${_index}`}
                        onMouseEnter={() => setHover(_timeline)}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => setActive({ key: _index, item: _timeline })}
                        key={_timeline}
                        className={`single-timeline ${(_timeline == active.item || _timeline == hover) ? "active" : ""}`}
                      >
                        <div className="timeline-circle">
                          <span className="timeline-text">{_timeline.split("_")[0]}</span>
                        </div>
                        <span className="timeline-circle"></span>
                        <span className="year-text">{_timeline.split("_")[0]}</span>
                      </div>
                    )
                  }
                </div>
                {
                  isEnabled && active.key < Object.keys(timelines).length - 1 &&
                  <span className="chevron right">
                    <button onClick={() => handleClickScroll(1)}><img src="/arrow-down.png" /></button>
                  </span>
                }
              </div>
            </div>
          </InfoWrapper>
        </InfoContainer>
      </Step>
    </Scrollama>
  );
};

export default InfoSection;
