import React, { useEffect, useState } from 'react';
import { Nav, NavbarContainer, NavLogo, LogoImage } from './NavbarElements';

const styles = {
  show: {
    transition: 'all 0.5s',
    opacity: 1,
    visibility: 'visible'
  },
  hidden: {
    transition: 'all 0.5s',
    opacity: 0,
    visibility: 'hidden'
  }
}

const Navbar = () => {
  const [visible, setVisible] = useState(true)

  const handleScroll = () => {
    setVisible(window.scrollY < 600)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <>
      <Nav style={visible ? styles.show : styles.hidden}>
        <NavbarContainer>
          <NavLogo to='/'>
            <LogoImage />
          </NavLogo>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
