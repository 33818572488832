import React, { useState } from 'react';
import CoverSection from '../components/CoverSection';
import Navbar from '../components/Navbar';
import InfoSection from '../components/InfoSection';
import Section2 from '../components/Section2';
import Section5 from '../components/Section5';
import CreditsSection from '../components/CreditsSection';

const Home = () => {
  const [currentProgress, setCurrentProgress] = useState(0);

  return (
    <>
      <Navbar />
      <CoverSection />
      <InfoSection controlProgress={currentProgress} />
      <Section2 currentProgress={currentProgress} setCurrentProgress={setCurrentProgress} />
      <Section5 />
      <CreditsSection />
    </>
  );
};

export default Home;
