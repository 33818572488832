import styled, { keyframes } from 'styled-components';

export const Section2Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 64px;
  position: relative;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 991.98px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  @media (max-width: 767.98px) {
    padding: 0 30px;
  }
  @media (max-width: 575.98px) {
    padding: 0 15px;
  }
`;

export const Scroller = styled.div`
  flex-basis: 80%;
  position: relative;

  @media (max-width: 1680px) {
    flex-basis: 50%;
  }
  @media (max-width: 1366px) {
    flex-basis: 57%;
  }
  @media (max-width: 1199.98px) {
    flex-basis: 55%;
  }
  @media (max-width: 991.98px) {
    flex-basis: 100%;
    margin-top: 55px;
  }
`;

export const SubtitleWrapper = styled.div`
  margin-bottom: 60px;
  
  @media (max-width: 991.98px) {
    margin-bottom: 45px;
  }
}
`;

export const Subtitle = styled.span`
  color: ${({ primary }) => (primary ? '#fff' : '#8CAA9D')}; 
  font-size: 29px;
  padding-right: 8px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    text-align: center;
    padding-right: 4px;
  }  
`;

export const CardContainer = styled.div`
  background-color: #353E3B55;
  text-align: left;
  display: none;
  width: 260%;
  position: absolute;
  border-radius: 5px;
  padding: 16px;
  top: 50px;
  left: -190px;
  @media screen and (max-width: 480px) {
    top: 30px;
    left: -90%;
  }  
`;

export const CardContainer1 = styled.div`
  background-color: #353E3B55;
  text-align: left;
  display: none;
  width: 472px;
  position: absolute;
  border-radius: 5px;
  padding: 16px;
  top: 80px;
  left: -242px;
  @media screen and (max-width: 480px) {
    top: 50px;
    width: 90vw;
    left: -387%;
  }  
`;

export const CardH5 = styled.h5`
  color: #fff;
  font-size: 13px;
  margin: 8px 0px;
`;

export const CardP = styled.p`
  color: #fff;
  font-size: 13px;
  margin: 2px 0px;
`;

export const HoverText = styled.span`
  color: #8CAA9D;  
  font-size: 29px;
  cursor: pointer;
  position: relative;
  // text-decoration: ${({ primary }) => (primary ? 'none' : 'underline')};
  &:hover {
    ${CardContainer} {
      display: block;
    }
  }
  padding-right: 10px;  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-right: 4px;
    z-index: 99;
  }  
`;

export const HoverText1 = styled.p`
  color: #8CAA9D;  
  font-size: 29px;
  cursor: pointer;
  position: relative;
  // text-decoration: ${({ primary }) => (primary ? 'none' : 'underline')};
  &:hover {
    ${CardContainer1} {
      display: block;
    }
  }
  padding-right: 10px;  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-right: 4px;
    z-index: 99;
  }  
`;

export const TextWrapper = styled.div`
  @media screen and (max-width: 480px) {
    text-align: center;
  }
  
`;

export const Graphics = styled.div`
  flex-basis: 20%;

  @media (max-width: 1680px) {
    flex-basis: 50%;
  }
  @media (max-width: 1366px) {
    flex-basis: 43%;
  }
  @media (max-width: 1199.98px) {
    flex-basis: 45%;
  }
  @media (max-width: 991.98px) {
    flex-basis: 100%;
  }
`;


export const DotDescWrapper = styled.div`
  display: block;
  animation: ${({ show }) => (show ? opacityOn2 : opacityOn3)} ${({ show }) => (show ? '2.8s' : '0.5s')} normal forwards;
  position: absolute;
  bottom: calc(100% + 6px);
  left: -41.5px;
  min-width: 123px;

  @media (max-width: 1680px) {
    left: -44px
  }
  @media (max-width: 1366px) {
    left: -51.5px;
    bottom: calc(100% + 2px);
  }
  @media (max-width: 991.98px) {
    min-width: 107px;
    left: -43.5px;
  }
  @media (max-width: 575.98px) {
    min-width: 92px;
    left: -36px;
  }
}
`;

export const DotDescP = styled.p`
  color: #ffffff;
  font-size: 15px;
  white-space: nowrap;

  @media (max-width: 991.98px) {
    font-size: 13px;
  }
  @media (max-width: 575.98px) {
    font-size: 11px;
  }
`;

export const DotWrapper = styled.div`
  position: fixed;
  left: 58%;
  top: 37%;

  @media (max-width: 991.98px) {
    display: flex;
    position: relative;
    left: 40%;
    top: auto;
  }
`;

export const DotWrapper1 = styled.div`
  position: fixed;
  left: 67%;
  top: 80%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    margin-top: 50px;
    left: calc(40% + 40px);
    top: auto;
  }
  @media (max-width: 575.98px) {
    left: calc(40% + 35px);
  }
`;

export const DotWrapper2 = styled.div`
  position: fixed;
  left: 75%;
  top: 20%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    margin-top: 50px;
    left: calc(40% - 120px);
    top: auto
  }
  @media (max-width: 575.98px) {
    left: calc(40% - 100px);
  }
`;

export const DotWrapper3 = styled.div`
  position: fixed;
  left: 78%;
  top: 40%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    margin-top: 100px;
    left: calc(40% + 150px);
    top: auto;
  }
  @media (max-width: 575.98px) {
    left: calc(40% + 130px);
    margin-top: 140px;
  }
`;

export const DotWrapper4 = styled.div`
  position: fixed;
  left: 85%;
  top: 10%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    bottom: 100px;
    left: calc(40% - 160px);
    top: auto;
  }
  @media (max-width: 575.98px) {
    left: calc(40% - 100px);
  }
`;

export const DotWrapper5 = styled.div`
  position: fixed;
  left: 54%;
  top: 70%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    left: calc(40% + 180px);
    bottom: 0;
    top: auto;
  }
  @media (max-width: 575.98px) {
    left: calc(40% + 135px);
  }
`;

export const DotWrapper6 = styled.div`
  position: fixed;
  left: 68%;
  top: 55%;

  @media (max-width: 991.98px) {
    display: flex;
    position: absolute;
    left: calc(40% + 130px);
    bottom: 150px;
    top: auto;
  }
  @media (max-width: 575.98px) {
    left: calc(40% + 110px);
  }
`;

export const DotsWrapper = styled.div`
  position: relative;
`;

const opacityOn = keyframes`
  0% {
      opacity: 0;
      visibility: visible;
  }
  100% {
      opacity: 1;
      visibility: visible;      
  }
`;

const opacityOn2 = keyframes`
  0% {
      opacity: 0;
      visibility: visible;
  }
  100% {
      opacity: 0.6;
      visibility: visible;      
  }
`;

const opacityOn3 = keyframes`
  0% {
      opacity: 0.6;
      visibility: visible;
  }
  100% {
      opacity: 0;
      visibility: visible;      
  }
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Img = styled.img`
  width: 40px;
  height: auto;
  visibility: ${({ isShow }) => (isShow ? 'visibile' : 'hidden')};
  animation: ${() => opacityOn} 3s normal forwards;
  animation: ${() => blink} 2s;
  animation-iteration-count: infinite;
  
  @media (max-width: 1680px) {
    width: 35px;
  }
  @media (max-width: 1366px) {
    width: 20px;
  }
`;
