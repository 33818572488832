const timelines = {
  "1883": "Gorsuch1863. Letter that states Confederate soldiers contaminated water supply of Union soldiers",
  "1930": `"A drink of water/ Jackson/1930s". View of woman drinking from pipe`,
  "1944": "People have to melt the light layer of snow to drink to have water during emergency",
  "1989": "Buy water quality with care",
  "1990": "No increases in taxes, water fees seen to fix pipes",
  "1990_2": "Water rebuilding plan a must for jackson",
  "1996": "Broken water mains because of ice storm caused Jackson to be under boil water notice",
  // "2002": "System serves many with little money to do it",
  // "2002_2": "Tap water clearly not clean as it looks",
  "2012": "Water quality good; surprised?",
  "2012_2": "It's a long and winding road for Jackson's drinking water",
  "2016": "Jackson's 1-cent plan nearly finalized"
  // "2017": "Water main issue hits schools: Jackson sites were asked to boil water",
  // "2021": "Lead found in water samples: 6 years after EPA violations, jackson's water still contaminated."
}

export default timelines
